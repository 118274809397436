<h2 mat-dialog-title>{{editing ? 'Edit' : 'Create'}} User</h2>
<mat-dialog-content>
  <div [formGroup]="userForm" class="ui grid" style="margin-top: unset; margin-bottom: unset;">
    <div class="eight wide column">
      <mat-form-field>
        <mat-label>First Name</mat-label>
        <input matInput placeholder="First Name" formControlName="firstName">
      </mat-form-field>
    </div>
    <div class="eight wide column">
      <mat-form-field>
        <mat-label>Last Name</mat-label>
        <input matInput placeholder="Last Name" formControlName="lastName">
      </mat-form-field>
    </div>
    <div class="eight wide column">
      <mat-form-field>
        <mat-label>Email Address</mat-label>
        <input matInput placeholder="Email Address" formControlName="email">
      </mat-form-field>
    </div>
    <div class="eight wide column">
      <mat-form-field>
        <mat-label>Permission Level</mat-label>
        <mat-select matNativeControl formControlName="permissionLevel">
          <mat-option *ngFor="let level of userLevels" [value]="level.id">
            {{level.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button color="warn" mat-dialog-close>Cancel</button>
  <button mat-raised-button color="primary" (click)="saveUser()" [disabled]="!userForm.valid" [mat-dialog-close]="true">{{editing ? 'Update' : 'Save'}} User</button>
</mat-dialog-actions>
