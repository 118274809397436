<div class="ui grid titlebar">
  <div class="ten wide column">
    <div class="title-box">
      <h2>All Users</h2>
      <span>View all users who have access to your system</span>
    </div>
  </div>
  <div class="six wide right aligned column">
    <button mat-raised-button color="primary" (click)="openDialog(false)">
      Add User
    </button>
  </div>
</div>
<div class="example-loading-shade"
     *ngIf="loading">
  <div class="ui active loader"></div>
</div>
<mat-form-field>
  <mat-label>Filter Users</mat-label>
  <input matInput (keyup)="applyFilter($event)" #input>
</mat-form-field>
<table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
  <ng-container matColumnDef="firstName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> First Name. </th>
    <td mat-cell *matCellDef="let element"> {{element.firstName | titlecase}} </td>
  </ng-container>
  <ng-container matColumnDef="lastName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Name. </th>
    <td mat-cell *matCellDef="let element"> {{element.lastName | titlecase}} </td>
  </ng-container>
  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef> Email. </th>
    <td mat-cell *matCellDef="let element"> {{element.email}} </td>
  </ng-container>
  <ng-container matColumnDef="role">
    <th mat-header-cell *matHeaderCellDef> Role. </th>
    <td mat-cell *matCellDef="let element"> {{getRoleName(element?.permissionLevel)}} </td>
  </ng-container>
  <ng-container matColumnDef="company">
    <th mat-header-cell *matHeaderCellDef> Company. </th>
    <td mat-cell *matCellDef="let element" style="cursor: pointer"> {{getCompanyName(element?.companyId)[0]?.name}} </td>
  </ng-container>
  <ng-container matColumnDef="active">
    <th mat-header-cell *matHeaderCellDef> Active. </th>
    <td mat-cell *matCellDef="let element" style="cursor: pointer"><mat-slide-toggle (change)="activeUser($event, element.id)" [checked]="element?.active"></mat-slide-toggle></td>
  </ng-container>
  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="openDialog(true, element)">
          <mat-icon>edit</mat-icon>
          <span>Edit</span>
        </button>
        <button mat-menu-item (click)="deleteUser(element.id)">
          <mat-icon>delete</mat-icon>
          <span>Delete</span>
        </button>
      </mat-menu>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

<mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
