<div class="ui stackable grid titlebar">
  <div class="two wide column">
    <button mat-raised-button color="primary" routerLink="/admin/companies">Back</button>
  </div>
  <div class="eleven wide column">
    <h1>{{company?.name}}</h1>
  </div>
  <div class="three wide column">
    <mat-form-field>
      <mat-label>Select Role</mat-label>
      <mat-select (selectionChange)="setRole($event)" [(ngModel)]="selectedRole" name="role">
        <mat-option *ngFor="let role of roles" [value]="role.id">
          {{role.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
<div class="ui stackable grid">
  <div class="sixteen wide column">
  </div>

<!--  <div style="width: 100%; height: auto;">-->
<!--    <mat-tab-group>-->
<!--      <mat-tab label="Dashboard">-->
<!--        <app-power-bi-dashboard [powerbiId]="company.powerBiKey"></app-power-bi-dashboard>-->
<!--      </mat-tab>-->
<!--      <mat-tab label="Documents">-->
<!--        <mat-card class="mat-elevation-z8">-->
<!--          <app-company-files [rootDir]="company.sharepointKey"></app-company-files>-->
<!--        </mat-card> </mat-tab>-->
<!--      <mat-tab label="Users">-->
<!--        <app-company-users [companyId]="companyId"></app-company-users>-->
<!--      </mat-tab>-->
<!--    </mat-tab-group>-->
<!--  </div>-->
  <div class="sixteen wide column">
    <app-company-users [companyId]="companyId"></app-company-users>
  </div>
  <div class="sixteen wide column">
    <div class="title-box">
      <h2>Documents</h2>
    </div>
    <mat-card class="mat-elevation-z8"><app-company-files [companyId]="companyId" [role]="selectedRole"></app-company-files></mat-card>
  </div>
  <div class="sixteen wide column">
    <app-company-powerbi [companyId]="companyId"></app-company-powerbi>
  </div>
</div>
