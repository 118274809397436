import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class CompanyApiService {

  constructor(
    private http: HttpClient
  ) { }

  getAllCompanies(): Observable<any> {
    return this.http.get(environment.apiUrl + 'companies');
  }

  getCompany(id): Observable<any> {
    return this.http.get(environment.apiUrl + 'companies/' + id);
  }

  updateCompany(id, data): Observable<any> {
    return this.http.patch(environment.apiUrl + 'companies/' +  id, data);
  }

  addCompany(data): Observable<any> {
    return this.http.post(environment.apiUrl + 'companies', data);
  }

  deleteCompany(data): Observable<any> {
    return this.http.delete(environment.apiUrl + 'companies/' + data);
  }

}
