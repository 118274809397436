import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class RolesService {

  constructor(
    private http: HttpClient
  ) { }

  getAllRoles(): Observable<any> {
    return this.http.get(environment.apiUrl + 'roles');
  }

  getRole(id): Observable<any> {
    return this.http.get(environment.apiUrl + 'roles/' + id);
  }

  updateRole(id, data): Observable<any> {
    return this.http.patch(environment.apiUrl + 'roles/' + id, data);
  }

  addRole(data): Observable<any> {
    return this.http.post(environment.apiUrl + 'roles', data);
  }

  deleteRole(id): Observable<any> {
    return this.http.delete(environment.apiUrl + 'roles/' + id);
  }

}
