import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'volante-portal-app';

  time;

  ngOnInit() {
    this.time = Date.now().toString().substr(8);
  }


}
