import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-config-roles',
  templateUrl: './config-roles.component.html',
  styleUrls: ['./config-roles.component.scss']
})
export class ConfigRolesComponent implements OnInit {

  constructor() { }

  roles = [
    {name: 'admin', _id: 1234},
    {name: 'executive', _id: 1},
    {name: 'standard', _id: 2},
    {name: 'bdx', _id: 3},
  ]

  ngOnInit(): void {
  }

  addRole() {
    this.roles.push({name: '', _id: this.roles.length});
    // console.log(this.roles);
  }

  deleteRole(i) {
    this.roles.splice(i, 1);
  }
}
