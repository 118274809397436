import { Component, OnInit } from '@angular/core';
import { SharepointApiService } from "../../shared/services/api/sharepoint-api.service";

@Component({
  selector: 'app-file-manager',
  templateUrl: './file-manager.component.html',
  styleUrls: ['./file-manager.component.scss']
})
export class FileManagerComponent implements OnInit {

  rootDir = '';
  currentDir;
  company = JSON.parse(localStorage.getItem('company'));
  initFolders = JSON.parse(this.company.sharepointKey);
  folders;
  user = JSON.parse(localStorage.getItem('user'));
  files = [];
  loading = true;
  isInit = true;
  downloading = false;
  constructor(
    private spAPI: SharepointApiService
  ) { }

  ngOnInit(): void {
    this.showInitFolders();
  }

  showInitFolders() {
    this.isInit = true;
    this.folders = [];
    this.files = [];
    this.initFolders = this.initFolders.filter(folders => folders.role === this.user.permissionLevel);
    this.folders = this.initFolders[0].subtasks;
    this.folders = this.dedupe(this.folders).sort(this.compare);
    this.loading = false;
    // this.goToFolder();
    this.currentDir = '';
  }

  dedupe(folders) {
    return folders.filter((folder, index, self) =>
      index === self.findIndex((t) => (
        t.id === folder.id
      ))
    )
  }

  goToFolder(folder = null, up = false) {
    // console.log(folder);
    this.isInit = false;
    this.loading = true;
    let newDir;
    if (up) {
      newDir = this.currentDir.split('/');
      newDir.pop();
      newDir = newDir.join('/');
    } else {
      newDir = (this.currentDir) ? this.currentDir + '/' + folder.name : folder.name;
    }

    const spDir = folder.baseUrl;

    this.spAPI.goToClientFolder(newDir, spDir).subscribe((res) => {
      // console.log(res);
      this.folders = res.folders;
      this.folders = this.dedupe(this.folders).sort(this.compare);
      this.files = res.files;
      this.currentDir = newDir;
      this.loading = false;
    })
    // console.log(this.folders, this.files);
  }

  getPrevDir() {
    this.showInitFolders();
  }

  downloadFile(folder, dir, file) {
    this.downloading = true;
    const input = {
      spDir: folder.baseUrl,
      dlDir: "Shared Documents/" + dir,
      dlFile: file
    }
    this.spAPI.downloadFileNew(input).add((logic) => {
      console.log(logic, 'Downloading Ended');
      this.downloading = false;
    });
  }

  compare( a, b ) {
    if ( a.name < b.name ){
      return -1;
    }
    if ( a.name > b.name ){
      return 1;
    }
    return 0;
  }

}
