<form>
  <div *ngFor="let role of roles; let i = index">
    <mat-form-field class="example-full-width">
      <mat-label>Role Name</mat-label>
      <input matInput placeholder="Role Name - {{role._id}}" [value]="role.name | titlecase">
      <button mat-button matSuffix mat-icon-button aria-label="Delete" disabled (click)="deleteRole(i)">
        <mat-icon>delete_forever</mat-icon>
      </button>
    </mat-form-field>
  </div>
  <button mat-raised-button color="primary" (click)="addRole()" disabled>Add New Role</button>
  <button mat-raised-button color="primary" (click)="addRole()" style="margin-left: 10px;" disabled>Save Roles</button>
</form>
