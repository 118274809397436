import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {SharepointApiService} from "../../shared/services/api/sharepoint-api.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {GroupsApiService} from "../../shared/services/api/groups-api.service";
import {GroupModel} from "../../shared/models/groups.model";
import {CompanyApiService} from "../../shared/services/api/company-api.service";
import {MatPaginator} from "@angular/material/paginator";
import {SnackService} from "../../shared/services/snack/snack.service";
import {MatSort} from "@angular/material/sort";
import {CompanyDeleteDialog, CompanyDialog} from "../companies/companies-list/companies-list.component";

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss']
})
export class GroupsComponent implements OnInit {

  ELEMENT_DATA: GroupModel[];
  displayedColumns: string[] = ['name', 'companies', 'actions'];
  dataSource = new MatTableDataSource([]);
  expandedElement: GroupModel | null;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    public dialog: MatDialog,
    private groupsApi: GroupsApiService,
    private router: Router,
    private snack: SnackService
  ) { }

  ngOnInit(): void {
    this.getGroups();
  }

  getGroups() {
    this.groupsApi.getAllGroups().subscribe((res: GroupModel[]) => {
      this.dataSource = new MatTableDataSource(res);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    })
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  openDialog(edit = false, data: GroupModel = {name: null, companies: [], id: null}) {
    const dialogRef = this.dialog.open(GroupsDialog, {width: '600px', data: {edit, data}});
    dialogRef.afterClosed().subscribe(result => {
      setTimeout(() => {
        if(result) {
          this.snack.openSnackBar('Group Saved.');
          this.getGroups();
        }
      }, 500);
    });
  }

  goto(id) {
    this.router.navigate(['/admin/companies/' + id]);
  }

  delete(id) {
    this.groupsApi.deleteGroup(id).subscribe(() => {
      setTimeout(() => {
        this.getGroups();
      }, 500);
    })
  }

  deleteGroup(id) {
    const dialogRef = this.dialog.open(GroupDeleteDialog, {width: '250px', data: {id}});

    dialogRef.afterClosed().subscribe((result) => {
      setTimeout(() => {
        this.getGroups();
      }, 500);
    });
  }

}

@Component({
  selector: 'groups-dialog',
  templateUrl: './groups.dialog.html',
})
export class GroupsDialog {

  groupsForm;
  editing = false;
  companies = [];
  LoadedData: GroupModel;

  constructor(
    public dialogRef: MatDialogRef<GroupsDialog>,
    private sharePointApi: SharepointApiService,
    private groupsApi: GroupsApiService,
    private companyApi: CompanyApiService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.LoadedData = data.data;
    this.editing = (data.edit);
    this.groupsForm = new FormGroup({
      name: new FormControl(data.data.name, Validators.required),
      companies: new FormControl(data.data.companies),
    })
    this.companyApi.getAllCompanies().subscribe((res) => {
      this.companies = res;
    })
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  saveGroup() {
    this.groupsForm.value.id = this.LoadedData.id;
    const state = (this.editing) ? 'updateGroup' : 'addGroup';
    this.groupsApi[state](this.groupsForm.value).subscribe((res) => {
      this.dialogRef.close(true);
    }, err => {
      // console.log(err);
    });
  }

}

@Component({
  selector: 'group-delete-dialog',
  templateUrl: './group-delete.dialog.html',
})
export class GroupDeleteDialog {
  dataBound;
  constructor(
    public dialogRef: MatDialogRef<CompanyDialog>,
    private groupApi: GroupsApiService,
    @Inject(MAT_DIALOG_DATA) public data,
    private snack: SnackService
  ) {
    this.dataBound = data;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  deleteCompany() {
    this.groupApi.deleteGroup(this.dataBound.id).subscribe(() => {
      this.dialogRef.close();
      this.snack.openSnackBar('Group Deleted.');
    })
  }
}
