<div class="title-box">
  <h2>Settings</h2>
  <span>Update your settings</span>
</div>
<mat-card class="mat-elevation-z8">
  <div class="formTitle">Your Details</div>
  <form class="form">
    <mat-form-field class="full-width">
      <mat-label>First Name</mat-label>
      <input matInput placeholder="First Name" disabled [value]="user?.firstName">
    </mat-form-field>
    <mat-form-field class="full-width">
      <mat-label>Last Name</mat-label>
      <input matInput placeholder="Last Name" disabled [value]="user?.lastName">
    </mat-form-field>
    <mat-form-field class="full-width">
      <mat-label>Email Address</mat-label>
      <input matInput placeholder="Email Address" disabled [value]="user?.email">
    </mat-form-field>
    <div class="actions">
      <button class="ui primary button" disabled (click)="save()">
        Save
      </button>
      <button class="ui button" disabled>
        Discard
      </button>
    </div>
  </form>
</mat-card>
