import { Component, OnInit } from '@angular/core';
import {SharepointApiService} from "../../../shared/services/api/sharepoint-api.service";

interface Folder {
  SHAREPOINT_URL: string,
  SHAREPOINT_DIR_PATH: string,
  id?: string
}

@Component({
  selector: 'app-config-sharepoint',
  templateUrl: './config-sharepoint.component.html',
  styleUrls: ['./config-sharepoint.component.scss']
})
export class ConfigSharepointComponent implements OnInit {

  folders: Folder[] = [];

  constructor(
    private spAPI: SharepointApiService
  ) {}

  ngOnInit() {
    this.spAPI.getSPISettings().subscribe((res) => {
      // console.log(res);
      this.folders = res;
    })
  }

  addFolder() {
    this.folders.push({
      SHAREPOINT_URL: '',
      SHAREPOINT_DIR_PATH: ''
    });
  }

  deleteFolder(i, id) {
    if (id) {
      // console.log('removing from server', this.folders[i].id);
      this.spAPI.deleteSPISettings(this.folders[i].id).subscribe();
    }
    this.folders.splice(i, 1);
  }

  updateFolder(index, field, $event) {
    this.folders[index][field] = $event.target.value;
  }

  saveFolder() {
    // console.log(this.folders);
    for (let i = 0; i < this.folders.length; i++) {
      // console.log(this.folders[i]);
      if (this.folders[i]['id']) {
        this.spAPI.patchSPISettings(this.folders[i]).subscribe((res) => {
          // console.log(res);
        }, err => {
          // console.log(err)
        });
      } else {
        this.spAPI.postSPISettings(this.folders[i]).subscribe((res) => {
          // console.log(res);
        }, err => {
          // console.log(err)
        });
      }
    }
  }

}
