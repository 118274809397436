import {Component, Inject, Input, OnInit} from '@angular/core';
import {UserModel} from "../../../../shared/models/user.model";
import {UsersApiService} from "../../../../shared/services/api/users-api.service";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {SharepointApiService} from "../../../../shared/services/api/sharepoint-api.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {environment} from "../../../../../environments/environment.prod";

@Component({
  selector: 'app-company-users',
  templateUrl: './company-users.component.html',
  styleUrls: ['./company-users.component.scss']
})
export class CompanyUsersComponent implements OnInit {

  @Input() companyId;

  ELEMENT_DATA: UserModel[];
  displayedColumns: string[] = ['fname', 'lname', 'role', 'email', 'active'];
  dataSource = [];
  loading = true;
  roles = [];

  constructor(
    public dialog: MatDialog,
    private userApi: UsersApiService
  ) {
    this.roles = JSON.parse(localStorage.getItem('roles'));
  }

  ngOnInit(): void {
    this.getUsers();
  }

  getRoleName(roleId) {
    // console.log(roleId);
    const role = this.roles.filter((level) => level.id === roleId );
    // console.log(role);
    return role[0].name;
  }

  getUsers() {
    this.userApi.getCompanyUsers(this.companyId).subscribe((res: UserModel[]) => {
      this.loading = false;
      this.dataSource = res;
    })
  }

  openDialog(edit = false, data: UserModel = {
    firstName: null,
    lastName: null,
    companyId: this.companyId,
    email: null,
    permissionLevel: null,
    active: false
  }) {
    const dialogRef = this.dialog.open(UserDialog, {width: '600px', data: {edit, data}});

    dialogRef.afterClosed().subscribe(result => {
      setTimeout(() => {
        this.getUsers();
      }, 1000);
    });
  }

  deleteUser(id) {
    this.userApi.deleteUser(id).subscribe((res) => {
      setTimeout(() => {
        this.getUsers();
      }, 1000);
    }, err => {
      // console.log(err);
    })
  }

  activeUser(event, id) {
    this.userApi.activateUser(id, event.checked).subscribe();
    console.log(event);
  }

}

@Component({
  selector: 'user-dialog',
  templateUrl: './user.dialog.html',
})
export class UserDialog {

  userForm;
  editing = false;
  userLevels = environment.userLevels
  companyId;

  constructor(
    public dialogRef: MatDialogRef<UserModel>,
    private usersApi: UsersApiService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.companyId = data.data.companyId;
    this.editing = (data.edit);
    this.userForm = new FormGroup({
      firstName: new FormControl(data.data.firstName, Validators.required),
      lastName: new FormControl(data.data.lastName, Validators.required),
      email: new FormControl(data.data.email, [Validators.required, Validators.email]),
      permissionLevel: new FormControl(data.data.permissionLevel, Validators.required)
    })
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  saveUser() {
    this.userForm.value.companyId = this.companyId;
    this.usersApi.addUser(this.userForm.value).subscribe((res) => {
      this.dialogRef.close();
    }, err => {
      // console.log(err);
    })
  }

}
