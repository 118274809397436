<div class="ui grid titlebar">
  <div class="ui ten wide column">
    <div class="title-box">
      <h2>Groups</h2>
      <span>View/Edit groups to assign to companies</span>
    </div>
  </div>
  <div class="six wide right aligned column">
    <button mat-raised-button color="primary" (click)="openDialog(false)">Create a Group</button>
  </div>
</div>

<mat-form-field>
  <mat-label>Filter Groups</mat-label>
  <input matInput (keyup)="applyFilter($event)" #input>
</mat-form-field>

<table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Name. </th>
    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
  </ng-container>

  <ng-container matColumnDef="companies">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Companies. </th>
    <td mat-cell *matCellDef="let element"> {{element.companies.length}} </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="openDialog(true, element)">
          <mat-icon>edit</mat-icon>
          <span>Edit</span>
        </button>
        <button mat-menu-item (click)="deleteGroup(element.id)">
          <mat-icon>delete</mat-icon>
          <span>Delete</span>
        </button>
      </mat-menu>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

<mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
