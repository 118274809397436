import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PublicComponent } from './public/public.component';
import { PrivateComponent } from './private/private.component';
import { LoginComponent } from './public/login/login.component';
import { DashboardComponent } from './private/dashboard/dashboard.component';
import { FileManagerComponent } from './private/file-manager/file-manager.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {MatCardModule} from '@angular/material/card';
import {MatMenuModule} from '@angular/material/menu';
import { SettingsComponent } from './private/settings/settings.component';
import {MatSelectModule} from "@angular/material/select";
import {MatInputModule} from "@angular/material/input";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {HttpClientModule,HTTP_INTERCEPTORS} from "@angular/common/http";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatTableModule} from "@angular/material/table";
import {MatButtonModule} from "@angular/material/button";
import {MatSortModule} from "@angular/material/sort";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatListModule} from "@angular/material/list";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatDialogModule} from "@angular/material/dialog";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatNativeDateModule} from "@angular/material/core";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatStepperModule} from "@angular/material/stepper";
import {MatExpansionModule} from "@angular/material/expansion";
import { LogoutComponent } from './private/logout/logout.component';
import { AdminComponent } from './admin/admin.component';
import { CompaniesComponent } from './admin/companies/companies.component';
import { UsersComponent } from './admin/users/users.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import {
  CompaniesListComponent,
  CompanyDeleteDialog,
  CompanyDialog
} from './admin/companies/companies-list/companies-list.component';
import {CompaniesSingleComponent} from './admin/companies/companies-single/companies-single.component';
import {CompanyUsersComponent, UserDialog} from './admin/companies/companies-single/company-users/company-users.component';
import {UsersAdminComponent, UserDialogAdmin, UserDeleteDialog} from './admin/users-admin/users-admin.component';
import { SettingsAdminComponent } from './admin/settings-admin/settings-admin.component';
import { CompanyFilesComponent } from './admin/companies/companies-single/company-files/company-files.component';
import { FileSizePipe } from './shared/pipes/filesize.pipe';
import { PowerBIDashboardComponent } from './shared/components/power-bi-dashboard/power-bi-dashboard.component';
import { SharedPointDirectoriesComponent } from './shared/components/shared-point-directories/shared-point-directories.component';
import {MatTabsModule} from '@angular/material/tabs';
import {GroupDeleteDialog, GroupsComponent, GroupsDialog} from './admin/groups/groups.component';
import { SharedPointDirectoriesTestComponent } from './shared/components/shared-point-directories-test/shared-point-directories-test.component';
import { ConfigComponent } from './admin/config/config.component';
import { ConfigSharepointComponent } from './admin/config/config-sharepoint/config-sharepoint.component';
import { ConfigPowerbiComponent } from './admin/config/config-powerbi/config-powerbi.component';
import { ConfigRolesComponent } from './admin/config/config-roles/config-roles.component';
import {SnackService} from "./shared/services/snack/snack.service";
import { CompanyPowerbiComponent } from './admin/companies/companies-single/company-powerbi/company-powerbi.component';
import { ReportsComponent } from './admin/reports/reports.component';
import {AuthService} from "./shared/services/auth/auth.service";
import { TokenInterceptor } from './private/interceptor/token.interceptor';
@NgModule({
  declarations: [
    AppComponent,
    PublicComponent,
    PrivateComponent,
    LoginComponent,
    DashboardComponent,
    FileManagerComponent,
    SettingsComponent,
    LogoutComponent,
    AdminComponent,
    CompaniesComponent,
    UsersComponent,
    ErrorPageComponent,
    CompaniesListComponent,
    CompaniesSingleComponent,
    CompanyUsersComponent,
    UserDialog,
    UserDialogAdmin,
    UserDeleteDialog,
    CompanyDeleteDialog,
    CompanyDialog,
    GroupsDialog,
    GroupDeleteDialog,
    UsersAdminComponent,
    SettingsAdminComponent,
    CompanyFilesComponent,
    FileSizePipe,
    PowerBIDashboardComponent,
    SharedPointDirectoriesComponent,
    GroupsComponent,
    SharedPointDirectoriesTestComponent,
    ConfigComponent,
    ConfigSharepointComponent,
    ConfigPowerbiComponent,
    ConfigRolesComponent,
    CompanyPowerbiComponent,
    ReportsComponent,
  ],
  imports: [
    FormsModule,
    HttpClientModule,
    BrowserModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatIconModule,
    MatCardModule,
    MatMenuModule,
    MatSelectModule,
    MatInputModule,
    MatCardModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatTableModule,
    MatSelectModule,
    MatSortModule,
    MatIconModule,
    MatPaginatorModule,
    MatMenuModule,
    MatSnackBarModule,
    MatProgressBarModule,
    MatCheckboxModule,
    MatListModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    MatStepperModule,
    MatExpansionModule,
    MatTabsModule,
    // JwtModule.forRoot({})
  ],
  providers: [
    // AuthGuardService,
    // AuthService,
    // RoleGuardService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    SnackService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
