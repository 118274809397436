<div class="folders">
  <div class="folder ui grid" *ngFor="let folder of folders; let i = index">
    <div class="seven wide column" style="padding-top: unset; padding-bottom: unset;">
      <mat-form-field>
        <mat-label>SharePoint URL</mat-label>
        <input matInput placeholder="SharePoint URL" [value]="folder.SHAREPOINT_URL" (change)="updateFolder(i, 'SHAREPOINT_URL', $event)" [id]="'SHAREPOINT_URL_' + i">
      </mat-form-field>
    </div>
    <div class="seven wide column" style="padding-top: unset; padding-bottom: unset;">
      <mat-form-field>
        <mat-label>SharePoint Dir</mat-label>
        <input matInput placeholder="SharePoint Dir" [value]="folder.SHAREPOINT_DIR_PATH" (change)="updateFolder(i, 'SHAREPOINT_DIR_PATH', $event)" [id]="'SHAREPOINT_DIR_PATH_' + i">
      </mat-form-field>
    </div>
    <div class="two wide column">
      <span class="material-icons" style="cursor: pointer;" (click)="deleteFolder(i, folder['id'])">delete_forever</span>
    </div>
  </div>
</div>

<div class="actions" style="margin-top: 20px;">
  <button mat-raised-button color="primary" (click)="addFolder()">Add New Config</button>
  <button style="margin-left: 10px;" mat-raised-button color="primary" (click)="saveFolder()">Save Config</button>
</div>
