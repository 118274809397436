import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {SharepointApiService} from "../../../../shared/services/api/sharepoint-api.service";
import {CompanyApiService} from "../../../../shared/services/api/company-api.service";
import {FileAndFoldersService} from "../../../../shared/services/storage/file-and-folders.service";

@Component({
  selector: 'app-company-files',
  templateUrl: './company-files.component.html',
  styleUrls: ['./company-files.component.scss']
})
export class CompanyFilesComponent implements OnInit, OnChanges {

  rootDir = '';
  currentDir;
  @Input() companyId;
  @Input() role;
  company;
  initFolders;
  folders = [];
  user = JSON.parse(localStorage.getItem('user'));
  files = [];
  loading = true;
  isInit = true;
  downloading = false;

  constructor(
    private spAPI: SharepointApiService,
    private companyApi: CompanyApiService,
    private ffService: FileAndFoldersService
  ) { }

  ngOnInit(): void {
    this.role = this.ffService.getAdminRole()
  }

  ngOnChanges(): void {
    this.getFolders();
  }

  getFolders() {
    this.companyApi.getCompany(this.companyId).subscribe((res) => {
      this.company = res;
      if (this.company.sharepointKey !== undefined) {
        this.initFolders = JSON.parse(this.company.sharepointKey);
        this.showInitFolders();
      }
    })
  }

  dedupe(folders) {
    return folders.filter((folder, index, self) =>
      index === self.findIndex((t) => (
        t.id === folder.id
      ))
    )
  }

  showInitFolders() {
    this.isInit = true;
    this.folders = [];
    this.files = [];
    this.initFolders = this.initFolders.filter(folders => folders.role === this.role);
    if (this.initFolders.length > 0) {
      this.folders = this.initFolders[0].subtasks;
      this.folders = this.dedupe(this.folders.sort(this.compare));
    }
    this.loading = false;
    // this.goToFolder();
    this.currentDir = '';
  }

  compare( a, b ) {
    if ( a.name < b.name ){
      return -1;
    }
    if ( a.name > b.name ){
      return 1;
    }
    return 0;
  }

  goToFolder(folder = null, up = false) {
    this.isInit = false;
    this.loading = true;
    let newDir;
    if (up) {
      newDir = this.currentDir.split('/');
      newDir.pop();
      newDir = newDir.join('/');
    } else {
      newDir = (this.currentDir) ? this.currentDir + '/' + folder.name : folder.name;
    }

    const spDir = folder.baseUrl;

    this.spAPI.goToClientFolder(newDir, spDir).subscribe((res) => {
      this.folders = res.folders.sort(this.compare);
      this.files = res.files.sort(this.compare);
      this.currentDir = newDir;
      this.loading = false;
    })
  }

  getPrevDir() {
    this.showInitFolders();
  }

  downloadFile(folder, dir, file) {
    console.log(folder, dir, file);
    this.downloading = true;
    const input = {
      spDir: folder.baseUrl,
      dlDir: "Shared Documents/" + dir,
      dlFile: file
    }
    this.spAPI.downloadFileNew(input).add((logic) => {
      console.log(logic, 'Downloading Ended');
      this.downloading = false;
    });
  }

}
