import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LoginComponent} from './public/login/login.component';
import {PublicComponent} from './public/public.component';
import {PrivateComponent} from './private/private.component';
import {DashboardComponent} from './private/dashboard/dashboard.component';
import {FileManagerComponent} from './private/file-manager/file-manager.component';
import {SettingsComponent} from './private/settings/settings.component';
import {LogoutComponent} from './private/logout/logout.component';
import {AdminComponent} from "./admin/admin.component";
import {ErrorPageComponent} from "./error-page/error-page.component";
import {CompaniesComponent} from "./admin/companies/companies.component";
import {CompaniesListComponent} from "./admin/companies/companies-list/companies-list.component";
import {CompaniesSingleComponent} from "./admin/companies/companies-single/companies-single.component";
import {UsersAdminComponent} from "./admin/users-admin/users-admin.component";
import {SettingsAdminComponent} from "./admin/settings-admin/settings-admin.component";
import {GroupsComponent} from "./admin/groups/groups.component";
import {SharedPointDirectoriesTestComponent} from "./shared/components/shared-point-directories-test/shared-point-directories-test.component";
import {ConfigComponent} from "./admin/config/config.component";
// import {AuthGuardService as AuthGuard} from './shared/services/auth/auth-guard.service';
// import {RoleGuardService} from "./shared/services/auth/role-guard.service";
import {ReportsComponent} from "./admin/reports/reports.component";

const routes: Routes = [
  {path: '', component: PublicComponent, children: [
      {path: '', redirectTo: 'login', pathMatch: 'full'},
      {path: 'login', component: LoginComponent},
    ]},
  {path: 'portal', component: PrivateComponent, children: [
      {path: '', redirectTo: 'report', pathMatch: 'full'},
      {path: 'report', component: DashboardComponent},
      {path: 'documents', component: FileManagerComponent},
      {path: 'settings', component: SettingsComponent},
      {path: 'logout', component: LogoutComponent}
    ]},
  {path: 'test', component: SharedPointDirectoriesTestComponent},
  {path: 'admin', component: AdminComponent, children: [
      {path: '', redirectTo: 'companies', pathMatch: 'full'},
      {path: 'companies', component: CompaniesComponent, children: [
          {path: '', redirectTo: 'list', pathMatch: 'full'},
          {path: 'list', component: CompaniesListComponent},
          {path: ':companyId', component: CompaniesSingleComponent},
        ]},
      {path: 'groups', component: GroupsComponent},
      {path: 'users', component: UsersAdminComponent},
      {path: 'settings', component: SettingsAdminComponent},
      {path: 'config', component: ConfigComponent},
    ]},
  {path: '**', component: ErrorPageComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
