import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable, Subscription} from "rxjs";
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class SharepointApiService {

  constructor(
    private http: HttpClient
  ) { }

  getAllFolders(): Observable<any> {
    return this.http.get(environment.apiUrl + 'sharepoint/listFolders/');
  }

  goToClientFolder(folder, spDir): Observable<any> {
    return this.http.post(environment.apiUrl + 'sharepoint/clientFoldersAndFiles/', {folder, spDir})
  }

  downloadFile(file) {
    this.http.post(environment.apiUrl + `sharepoint/getFile/`, file).subscribe((res: string) => {
      window.open(res);
    }, (err) => {
      console.log(err)
    })
  }

  download(url, filename): Promise<any> {
    const token = sessionStorage.getItem('msal.idtoken');
    const headers = { 'Authorization': `Bearer ${token}` }; // auth header with bearer token

    return fetch(url,{ headers } ).then(function (t) {
      return t.blob().then((b) => {
          var a = document.createElement("a");
          a.href = URL.createObjectURL(b);
          a.setAttribute("download", filename);
          a.click();
        }
      );
    });
  }

  downloadFileNew(file: { spDir: string, dlDir: string, dlFile: string }): Subscription {
    return this.http.post(environment.apiUrl + `sharepoint/getFileNew/`, file).subscribe((res: any) => {
      return this.download(environment.apiUrl + "download?file=" + res.file, file.dlFile).then((res) => {
        return true;
      });
    }, (err) => {
      console.log(err)
    })
  }

  getSPISettings(): Observable<any> {
    return this.http.get(environment.apiUrl + `settings`);
  }

  postSPISettings(data): Observable<any> {
    return this.http.post(environment.apiUrl + `settings`, {SHAREPOINT_URL: data.SHAREPOINT_URL, SHAREPOINT_DIR_PATH: data.SHAREPOINT_DIR_PATH});
  }

  patchSPISettings(data): Observable<any> {
    return this.http.patch(environment.apiUrl + `settings/` + data.id, {SHAREPOINT_URL: data.SHAREPOINT_URL, SHAREPOINT_DIR_PATH: data.SHAREPOINT_DIR_PATH});
  }

  deleteSPISettings(id): Observable<any> {
    return this.http.delete(environment.apiUrl + `settings/` + id);
  }

}
