<h2 mat-dialog-title>{{editing ? 'Edit' : 'Create'}} Group</h2>
<mat-dialog-content>
  <div [formGroup]="groupsForm" class="ui grid" style="margin-top: unset; margin-bottom: unset;">
    <div class="eight wide column">
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput placeholder="Name" formControlName="name">
      </mat-form-field>
    </div>
    <div class="eight wide column">
      <mat-form-field>
        <mat-label>Companies</mat-label>
        <mat-select multiple formControlName="companies">
          <mat-option *ngFor="let company of companies" [value]="company.id">{{company.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button color="warn" mat-dialog-close>Cancel</button>
  <button mat-raised-button color="primary" (click)="saveGroup()" [disabled]="!groupsForm.valid" [mat-dialog-close]="true">{{editing ? 'Update' : 'Save'}} Group</button>
</mat-dialog-actions>
