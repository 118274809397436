import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-config-powerbi',
  templateUrl: './config-powerbi.component.html',
  styleUrls: ['./config-powerbi.component.scss']
})
export class ConfigPowerbiComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
