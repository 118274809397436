<section class="example-section">
  <div class="loading" *ngIf="loading">
    <div class="ui active loader"></div>
  </div>
  <div class="example-list-section" *ngIf="!loading">
    <button mat-raised-button color="accent" (click)="rebuildFolders()" [disabled]="refreshing">Refresh Folders</button>
    <div *ngFor="let parent of newTasks; let j = index">
      <div style="font-weight: 600; font-size: 16px; padding: 20px 0;">{{parent.parent}}</div>
      <div *ngFor="let task of parent.subtasks; let i = index" style="margin-bottom:10px; margin-left: 20px;">
        <mat-checkbox class="example-margin" (change)="set(task)" [checked]="isSelected(task)">
          {{task.name}}
        </mat-checkbox>
      </div>
    </div>
<!--    <li *ngFor="let task of tasks; let i = index" style="margin-bottom:10px;">-->
<!--      <mat-checkbox class="example-margin" [indeterminate]="someComplete(i)" color="primary" (change)="setAll(i, $event.checked)">-->
<!--      <mat-checkbox class="example-margin" (change)="set(task)" [checked]="isSelected(task)">-->
<!--        {{task.name}}-->
<!--      </mat-checkbox>-->
<!--&lt;!&ndash;      <span class="subFolder example-list-section">&ndash;&gt;-->
<!--&lt;!&ndash;        <ul>&ndash;&gt;-->
<!--&lt;!&ndash;          <li *ngFor="let subtask of task.subtasks">&ndash;&gt;-->
<!--&lt;!&ndash;            <mat-checkbox [(ngModel)]="subtask.completed"&ndash;&gt;-->
<!--&lt;!&ndash;                          color="primary">&ndash;&gt;-->
<!--&lt;!&ndash;              {{subtask.name}}&ndash;&gt;-->
<!--&lt;!&ndash;            </mat-checkbox>&ndash;&gt;-->
<!--&lt;!&ndash;          </li>&ndash;&gt;-->
<!--&lt;!&ndash;        </ul>&ndash;&gt;-->
<!--&lt;!&ndash;      </span>&ndash;&gt;-->
<!--    </li>-->
  </div>
</section>
