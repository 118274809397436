<div class="ui middle aligned center aligned grid">
  <div class="column">
    <h2 class="ui image header">
      <img src="assets/img/volanteGlobalGreenNew.svg" class="image">
      <br/>
      <div class="content">
        Login to your account
      </div>
    </h2>
    <form class="ui large form" [formGroup]="loginForm" (ngSubmit)="login()">
      <div class="ui stacked segment">
        <div class="field">
          <div class="ui left icon input">
            <i class="user icon"></i>
            <input type="text" name="email" placeholder="E-mail address" formControlName="email">
          </div>
        </div>
        <button class="ui fluid large teal submit button" [disabled]="!loginForm.valid" (click)="login()">Login</button>
      </div>

      <div class="error" *ngIf="error">{{error}}</div>

    </form>

  </div>
</div>
