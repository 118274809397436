import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {UsersApiService} from "../../shared/services/api/users-api.service";
import {CompanyApiService} from "../../shared/services/api/company-api.service";
import {UserModel} from "../../shared/models/user.model";
import {CompanyModel} from "../../shared/models/company.model";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {environment} from "../../../environments/environment.prod";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {SnackService} from "../../shared/services/snack/snack.service";
import {MatSort} from "@angular/material/sort";
import {CompanyDeleteDialog, CompanyDialog} from "../companies/companies-list/companies-list.component";

@Component({
  selector: 'app-users-admin',
  templateUrl: './users-admin.component.html',
  styleUrls: ['./users-admin.component.scss']
})
export class UsersAdminComponent implements OnInit, AfterViewInit {

  companies = [];
  users = [];
  ELEMENT_DATA: UserModel[];
  displayedColumns: string[] = ['firstName', 'lastName', 'email', 'role', 'company', 'active', 'actions'];
  dataSource = new MatTableDataSource([]);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  loading = true;
  roles = [];

  constructor(
    private userApi: UsersApiService,
    private companyApi: CompanyApiService,
    public dialog: MatDialog,
    private snack: SnackService
  ) {
    this.roles = JSON.parse(localStorage.getItem('roles'));
  }

  ngOnInit(): void {
    this.companyApi.getAllCompanies().subscribe((companies: CompanyModel[]) => {
      this.companies = companies;
      this.getUsers();
    })
  }


  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getUsers() {
    this.userApi.getAllUsers().subscribe((res: UserModel[]) => {
      res = res.sort(this.compare);
      this.loading = false;
      // console.log(res);
      this.dataSource = new MatTableDataSource(res);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    })
  }

  compare( a, b ) {
    if ( a.firstName < b.firstName ){
      return -1;
    }
    if ( a.firstName > b.firstName ){
      return 1;
    }
    return 0;
  }

  getCompanyName(id) {
    return this.companies.filter((company) => {
      return (company._id === id) ? company.name : '';
    })
  }

  getRoleName(roleId) {
    // console.log(roleId);
    const role = this.roles.filter((level) => level.id === roleId );
    // console.log(role);
    return role[0].name;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.dataSource.sort = this.sort;
  }

  openDialog(edit = false, data: UserModel = {
    firstName: null,
    lastName: null,
    companyId: null,
    email: null,
    permissionLevel: null,
    active: false
  }) {
    const dialogRef = this.dialog.open(UserDialogAdmin, {width: '600px', data: {edit, data}});

    dialogRef.afterClosed().subscribe(result => {
      setTimeout(() => {
        this.getUsers();
      }, 1500);
    });
  }

  deleteUser(id) {
    const dialogRef = this.dialog.open(UserDeleteDialog, {width: '250px', data: {id}});

    dialogRef.afterClosed().subscribe((result) => {
      setTimeout(() => {
        this.getUsers();
      }, 500);
    });
  }

  activeUser(event, id) {
    this.userApi.activateUser(id, event.checked).subscribe();
    console.log(event);
  }

}

@Component({
  selector: 'user-dialog',
  templateUrl: './user.dialog.html',
})
export class UserDialogAdmin {

  userForm;
  editing = false;
  userLevels = [];
  companyId;
  companies: CompanyModel[];
  user;

  constructor(
    public dialogRef: MatDialogRef<UserModel>,
    private usersApi: UsersApiService,
    @Inject(MAT_DIALOG_DATA) public data,
    private companyApi: CompanyApiService,
    private snack: SnackService
  ) {
    this.userLevels = JSON.parse(localStorage.getItem('roles'));
    this.companyId = data.data.companyId;
    this.user = data;
    this.editing = (data.edit);
    this.userForm = new FormGroup({
      firstName: new FormControl(data.data.firstName, Validators.required),
      lastName: new FormControl(data.data.lastName, Validators.required),
      email: new FormControl(data.data.email, [Validators.required, Validators.email]),
      permissionLevel: new FormControl(data.data.permissionLevel, Validators.required),
      companyId: new FormControl(data.data.companyId, Validators.required),
      active: new FormControl(data.data.active, Validators.required)
    })
    this.companyApi.getAllCompanies().subscribe((companies: CompanyModel[]) => {
      this.companies = companies;
    })
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  saveUser() {
    console.log(this.userForm.value);
    this.userForm.value.email = this.userForm.value.email.toLowerCase();
    if (!this.editing) {
      this.usersApi.addUser(this.userForm.value).subscribe((res) => {
        this.snack.openSnackBar('User Saved.');
        this.dialogRef.close();
      }, err => {
        // console.log(err);
      })
    } else {
      this.usersApi.updateUser(this.user.data._id, this.userForm.value).subscribe((res) => {
        this.snack.openSnackBar('User Updated.');
        this.dialogRef.close();
      }, err => {
        // console.log(err);
      })
    }
  }

}

@Component({
  selector: 'user-delete-dialog',
  templateUrl: './user-delete.dialog.html',
})
export class UserDeleteDialog {
  dataBound;
  constructor(
    public dialogRef: MatDialogRef<CompanyDialog>,
    private userApi: UsersApiService,
    @Inject(MAT_DIALOG_DATA) public data,
    private snack: SnackService
  ) {
    this.dataBound = data;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  deleteUser() {
    this.userApi.deleteUser(this.dataBound.id).subscribe(() => {
      this.dialogRef.close();
      this.snack.openSnackBar('User Deleted.');
    })
  }

}
