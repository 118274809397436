import {Component, Input, OnInit} from '@angular/core';
import {SharepointApiService} from "../../services/api/sharepoint-api.service";
import {FileAndFoldersService} from "../../services/storage/file-and-folders.service";
import {Folder} from "@angular/compiler-cli/src/ngtsc/file_system/testing";

@Component({
  selector: 'app-shared-point-directories-test',
  templateUrl: './shared-point-directories-test.component.html',
  styleUrls: ['./shared-point-directories-test.component.scss']
})
export class SharedPointDirectoriesTestComponent implements OnInit {

  @Input() role;
  folders = [];
  tasks: any[];
  loading = true;
  @Input() selected: any = [];
  newSelected: any = [];
  parents = [];
  newTasks = [];
  initial = true;
  total = 0;
  refreshing = false;

  constructor(
    private spAPI: SharepointApiService,
    private spStore: FileAndFoldersService
  ) { }

  ngOnInit(): void {
    this.getFolders();
  }

  getFolders() {
    this.loading = true;
    this.tasks = [];
    this.refreshing = true;
    this.selected = this.selected.filter((x) => {
      return x.role === this.role;
    })[0];
    if (this.selected !== undefined && this.selected.subtasks.length > 0) {
      this.newSelected = this.selected.subtasks;
      this.tasks = this.spStore.listAllFolders();
      this.rebuildFolders();
    } else {
      this.newSelected = [];
      this.tasks = this.spStore.listAllFolders();
      this.rebuildFolders();
    }
  }

  rebuildFolders(refresh = true) {
    this.loading = true;
    this.refreshing = true;
    this.parents = [];
    this.newTasks = [];
    let folderList = [];
   /// folderList = (refresh) ? this.spStore.getAllFolders() : this.spStore.listAllFolders();
   folderList = this.spStore.getAllFolders();
    setTimeout(() => {
      folderList = folderList.sort(this.compareName);
      folderList.forEach((folder) => {
        let parent = folder.baseUrl.split('/');
        parent = parent[parent.length - 1].replace(/([A-Z0-9])/g, ' $1').replace('-', ' -').trim();
        folder['parent'] = parent;
        if (this.parents.indexOf(parent) === -1) {
          this.parents.push(parent);
          this.newTasks.push({parent, subtasks: [folder]});
        } else {
          this.newTasks[this.parents.indexOf(parent)].subtasks.push(folder);
        }
      })
      this.loading = false;
      this.refreshing = false;
      this.newTasks = this.newTasks.sort(this.compare);
    }, 1500);
  }

  compare( a, b ) {
    if ( a.parent < b.parent ){
      return -1;
    }
    if ( a.parent > b.parent ){
      return 1;
    }
    return 0;
  }

  compareName( a, b ) {
    if ( a.name < b.name ){
      return -1;
    }
    if ( a.name > b.name ){
      return 1;
    }
    return 0;
  }


  set(folder) {
    // console.log(1, this.newSelected);
    let index = this.folders.findIndex(x => x.role === this.role);
    if(index === -1) {
      this.folders.push({role: this.role, subtasks: [folder]})
      this.newSelected.push(folder);
      // console.log(2, this.newSelected);
    } else {
      let folderIndex = this.folders[index].subtasks.findIndex(folderI => folderI.id === folder.id);
      let selectedIndex = this.newSelected.findIndex(folderI => folderI.id === folder.id);
      if (selectedIndex === -1) {
        this.newSelected.push(folder);
        // console.log(3, this.newSelected);
      } else {
        this.newSelected.splice(selectedIndex, 1)
        // console.log(4, this.newSelected);
      }
      if (folderIndex === -1) {
        this.folders[index].subtasks.push(folder);
      } else {
        this.folders[index].subtasks.splice(folderIndex, 1);
      }
    }
    this.spStore.setFolders(this.folders);
  }

  initSet(folder) {
    let index = this.folders.findIndex(x => x.role === this.role);
    if(index === -1) {
      this.folders.push({role: this.role, subtasks: [folder]})
    } else {
      this.folders[index].subtasks.push(folder);
    }
    this.spStore.setFolders(this.folders);
  }

  isSelected(subtask): boolean {
      if (this.newSelected.length > 0) {
        return this.newSelected.filter((x) => {
          if (x.id === subtask.id) {
            if(this.initial) {
              this.initSet(subtask);
              this.total++;
              if(this.total >= this.newSelected.length) {
                this.initial = false;
              }
            }
            return true;
          } else {
            return false;
          }
        })
      }
    return false;
  }

}
