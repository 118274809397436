<h2 mat-dialog-title>{{editing ? 'Edit' : 'Create'}} Company</h2>
<mat-dialog-content>
  <div [formGroup]="companyForm" class="ui grid" style="margin-top: unset; margin-bottom: unset;">
    <div class="eight wide column">
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput placeholder="Name" formControlName="name">
      </mat-form-field>
    </div>
    <div class="eight wide column">
      <mat-form-field>
        <mat-label>PowerBI Reports</mat-label>
        <mat-select formControlName="powerBiKey">
          <mat-option [value]="stringify({dashboard: null, groupId: null})">No Report</mat-option>
          <mat-option *ngFor="let dashboard of powerBIDashboards" [value]="stringify({dashboard: dashboard.id, groupId: dashboard.groupID})">
            {{dashboard.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="sixteen wide column">
      <mat-tab-group>
        <mat-tab *ngFor="let role of roles" [label]="role?.name">
          <app-shared-point-directories-test [role]="role?.id" [selected]="(selected && selected.length > 0) ? toJSON(selected) : []"></app-shared-point-directories-test>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button color="warn" mat-dialog-close>Cancel</button>
  <button mat-raised-button color="primary" [disabled]="!companyForm.valid" [mat-dialog-close]="true" (click)="saveCompany()">{{editing ? 'Update' : 'Save'}} Company</button>
</mat-dialog-actions>
