<div class="public-container">
  <iframe src="https://player.vimeo.com/video/473078793?background=1&autoplay=1&loop=1&byline=0&title=0"
          frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
  <div class="ui middle aligned center aligned grid">
    <div class="column">
      <h2 class="ui image header">
        <img src="assets/img/volanteGlobalGreenNew.svg" class="image">
        <br/>
        <div class="content">
          <h1>404</h1>
          <span>The page you are trying to access doesn't exists.</span>
        </div>
        <div class="actions">
          <button routerLink="/" mat-raised-button color="primary" style="width: 200px;">Home</button>
        </div>
      </h2>
    </div>
  </div>
</div>
