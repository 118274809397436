import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class UsersApiService {

  constructor(
    private http: HttpClient
  ) { }

  getAllUsers(): Observable<any> {
    return this.http.get(environment.apiUrl + 'users');
  }

  getCompanyUsers(id): Observable<any> {
    return this.http.get(environment.apiUrl + 'users/company/' + id);
  }

  getUser(id): Observable<any> {
    return this.http.get(environment.apiUrl + 'users/' + id);
  }

  getUserByEmail(email): Observable<any> {
    return this.http.get(environment.apiUrl + 'userByEmail/' + email);
  }

  userExists(email): Observable<any> {
    return this.http.get(environment.apiUrl + 'userExists/' + email);
  }

  userExistsActive(email): Observable<any> {
    return this.http.get(environment.apiUrl + 'userExistsActive/' + email);
  }

  updateUser(id, data): Observable<any> {
    return this.http.patch(environment.apiUrl + 'users/' + id, data);
  }

  addUser(data): Observable<any> {
    return this.http.post(environment.apiUrl + 'users', data);
  }

  deleteUser(id): Observable<any> {
    return this.http.delete(environment.apiUrl + 'users/' + id);
  }

  activateUser(id, value): Observable<any> {
    let data = {active: value};
    return this.http.patch(environment.apiUrl + 'users/' + id, data)
  }

}
