import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class GroupsApiService {

  constructor(
    private http: HttpClient
  ) { }

  getAllGroups(): Observable<any> {
    return this.http.get(environment.apiUrl + 'groups');
  }

  getGroup(id): Observable<any> {
    return this.http.get(environment.apiUrl + 'group/' + id);
  }

  updateGroup(data): Observable<any> {
    return this.http.patch(environment.apiUrl + 'groups/' + data.id, data);
  }

  addGroup(data): Observable<any> {
    return this.http.post(environment.apiUrl + 'groups', data);
  }

  deleteGroup(id): Observable<any> {
    return this.http.delete(environment.apiUrl + 'groups/' + id);
  }

}
