// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  clientId: '054e2036-5fe8-45a2-b573-79509cf782c6',
  tenantId: '21492dd1-2915-4c82-a57f-3a54aedb2da4',
  clientIdB2C: '52dbc44a-7479-4bbb-bb8c-d07f5d247b15',
  tenantIdB2c: '054ff2ef-8ec8-45ef-ab28-26333e2a0d8b',
  apiUrl: 'https://portal-api.volanteglobal.com/',
  userLevels: [
    {id: 1, name: 'Carrier'},
    {id: 2, name: 'Broker'},
    {id: 3, name: 'Partner/Investor'},
    {id: 1234, name: 'Admin'}
  ]
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
