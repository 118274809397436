import { Injectable } from '@angular/core';
import {SharepointApiService} from "../api/sharepoint-api.service";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class FileAndFoldersService {

  folders = [];
  allFolders = [];
  adminRoleVar = '5fe0c7e3e68ed030dccdf9de';
  constructor(
    private sharePointApi: SharepointApiService
  ) { }

  getFolders() {
    return this.folders;
  }

  setFolders(folders) {
    let index = this.folders.findIndex(x => folders[0].role === x.role);
    if(index === -1) {
      this.folders.push(folders[0])
    } else {
      this.folders.splice(index, 1);
      this.folders.push(folders[0])
    }
    // this.folders = folders;
    // console.log(this.folders);
  }

  resetFolders() {
    this.folders = [];
  }

  getAllFolders() {
    this.sharePointApi.getAllFolders().subscribe((res) => {
      // console.log(res.folders);
      this.allFolders = res.folders;
    })
    return this.allFolders;
  }

  listAllFolders() {
    // console.log('LIST', this.allFolders);
    return this.allFolders;
  }

  setAdminRole(value) {
    this.adminRoleVar = value;
  }

  getAdminRole(): string {
    return this.adminRoleVar;
  }

}

