import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  company;
  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
    this.company = JSON.parse(localStorage.getItem('company'));
    console.log(this.company);
    if (this.company.powerBiKey === null || this.company.powerBiKey === "" || !this.company.powerBiKey) {
      this.router.navigate(['/portal/documents'])
    } else {
      const powerBI = JSON.parse(this.company.powerBiKey);
    }
  }

}
