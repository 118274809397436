<div class="ui visible left vertical inverted sidebar menu">
  <div class="logo" style="background-image: url(/assets/img/volanteGlobalGreen.svg)" routerLink="/admin/"></div>
  <!--  <a class="item" routerLink="/admin/dashboard" routerLinkActive="active">-->
<!--    <span class="material-icons">dashboard</span>-->
<!--    <span class="menu-item">Dashboard</span>-->
<!--  </a>-->
  <a class="item" routerLink="/admin/companies/" routerLinkActive="active">
    <span class="material-icons">business</span>
    <span class="menu-item">Companies</span>
  </a>
  <a class="item" routerLink="/admin/groups/" routerLinkActive="active">
    <span class="material-icons">group_work</span>
    <span class="menu-item">Groups</span>
  </a>
  <a class="item" routerLink="/admin/users/" routerLinkActive="active">
    <span class="material-icons">supervisor_account</span>
    <span class="menu-item">Users</span>
  </a>
  <a class="item" routerLink="/admin/config/" routerLinkActive="active">
    <span class="material-icons">settings</span>
    <span class="menu-item">Config</span>
  </a>
  <a class="item" (click)="logout()">
    <span class="material-icons">exit_to_app</span>
    <span class="menu-item">Logout</span>
  </a>
</div>
<div class="pusher">
<!--  <mat-toolbar color="primary">-->
<!--    <mat-toolbar-row>-->
<!--      <span class="spacer"></span>-->
<!--      <div class="userSettingsMenu" [matMenuTriggerFor]="menu">-->
<!--        <span>{{user}}</span>-->
<!--        <mat-icon class="menuSettingsMenu" aria-hidden="false" aria-label="User Settings Menu">account_circle</mat-icon>-->
<!--      </div>-->
<!--      <mat-menu #menu="matMenu">-->
<!--        <button mat-menu-item routerLink="/admin/settings/">-->
<!--          <mat-icon>settings</mat-icon>-->
<!--          User Settings-->
<!--        </button>-->
<!--        <button mat-menu-item (click)="logout()">-->
<!--          <mat-icon>exit_to_app</mat-icon>-->
<!--          Logout-->
<!--        </button>-->
<!--      </mat-menu>-->
<!--    </mat-toolbar-row>-->
<!--  </mat-toolbar>-->
  <router-outlet></router-outlet>
</div>
