import { Injectable } from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";

@Injectable({
  providedIn: 'root'
})
export class SnackService {

  constructor(
    private _snackBar: MatSnackBar
  ) {}

  openSnackBar(value, type = 'success') {
    this._snackBar.open((type === 'success') ? 'Success: ' + value : 'Error: ' + value, null, {
      duration: 1500,
      horizontalPosition: 'end',
      verticalPosition: 'bottom',
      panelClass: type
    });
  }

}
