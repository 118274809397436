import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import * as Msal from 'msal';
import {environment} from '../../../environments/environment';
import {CompanyApiService} from "../../shared/services/api/company-api.service";
import {UsersApiService} from "../../shared/services/api/users-api.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {


  loginForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email])
  });
  error = null;
  timeout;

  constructor(
    private router: Router,
    private userApi: UsersApiService,
    private companyApi: CompanyApiService
  ) { }

  ngOnInit(): void {
    let msalConfig = this.getConfig();
    let msalInstance = new Msal.UserAgentApplication(msalConfig);
    const user = msalInstance.getAccount();
    this.timeout = setInterval(() => {
      this.checkLogin();
    },  1000)
  }

  login(): void {
    this.error = null;
    const ssoRequest = {
      loginHint: this.loginForm.value.email.toLowerCase()
    };
    localStorage.setItem('account', this.loginForm.value.email.toLowerCase());
    let msalConfig = this.getConfig();
    let msalInstance = new Msal.UserAgentApplication(msalConfig);
    msalInstance.loginPopup(ssoRequest)
      .then(response => {
        // console.log('Here 1');
        localStorage.setItem('ms-vp', JSON.stringify(response));
        const user = msalInstance.getAccount();
        console.log(JSON.stringify(response));
        this.userApi.getUserByEmail(localStorage.getItem('account')).subscribe((res) => {
          if (res !== false && res.active) {
            localStorage.setItem('user', JSON.stringify(res));
            if (res.permissionLevel === '5fe0c272e68ed030dccdf9dd') {
              this.router.navigate(['/admin']);
            } else {
              this.companyApi.getCompany(res.companyId).subscribe((company) => {
                localStorage.setItem('company', JSON.stringify(company));
                this.router.navigate(['/portal']);
              })
            }
          } else {
            localStorage.clear();
            this.error = 'You do not have permission to access to the Volante Portal';
            return;
          }
        }, err => {
          return;
        })
      })
      .catch(error => {
        // console.log(error);
        // console.log('no access 3');
      });
  }

  checkLogin() {
    if (localStorage.getItem('account')) {
      let msalConfig = this.getConfig();
      let msalInstance = new Msal.UserAgentApplication(msalConfig);
      const user = msalInstance.getAccount();
      if (user && user.userName) {
        this.userApi.getUserByEmail(localStorage.getItem('account')).subscribe((res) => {
          console.log(res);
          if (res !== false && res.active) {
            clearInterval(this.timeout);
            localStorage.setItem('user', JSON.stringify(res));
            if (res.permissionLevel === '5fe0c272e68ed030dccdf9dd') {
              this.router.navigate(['/admin']);
            } else {
              this.companyApi.getCompany(res.companyId).subscribe((company) => {
                localStorage.setItem('company', JSON.stringify(company));
                this.router.navigate(['/portal']);
              })
            }
          } else  {
            // console.log('No User 1');
            return;
          }
        }, err => {
          // console.log('No User 2');
          return;
        })
      }
    }
  }

  getConfig() {
    let adminAccess = ['volanteglobal.com', 'volanteglobalcom.onmicrosoft.com', 'vgext.com'];
    let msalConfig;
    if (localStorage.getItem('account') && adminAccess.includes(localStorage.getItem('account').split('@')[1])) {
      msalConfig = {
        auth: {
          clientId: environment.clientId,
          tenantId: environment.tenantId,
          navigateToLoginRequestUrl: false
        },
        framework: {
          isAngular: true,
        }
      };
    } else {
      msalConfig = {
        auth: {
          clientId: environment.clientIdB2C,
          tenantId: environment.tenantIdB2c,
          navigateToLoginRequestUrl: false
        },
        framework: {
          isAngular: true,
        }
      };
    }
    return msalConfig;
  }

}
