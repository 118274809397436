<mat-card>
  <div class="title-box">
    <h2>Roles</h2>
  </div>
  <app-config-roles></app-config-roles>
</mat-card>

<mat-card>
  <div class="title-box">
    <h2>SharePoint Config</h2>
  </div>
  <app-config-sharepoint></app-config-sharepoint>
</mat-card>

<mat-card style="display: none;">
  <div class="title-box">
    <h2>PowerBI Config</h2>
  </div>
  <app-config-powerbi></app-config-powerbi>
</mat-card>
