import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class PowerbiApiService {

  constructor(
    private http: HttpClient
  ) { }

  getEmbed(groupId = '89b1b9bd-4f86-493b-8e6c-9ae2962070a8', dashboardId = '810cb451-5d19-4e7d-8427-e49dee11220f') {
    return this.http.get(environment.apiUrl + `dashboard/${groupId}/${dashboardId}`);
  }

  getReport(groupId = '9a21659e-4787-4c93-a022-5dfe29d2f56a', reportId = '535d16b4-842d-4c8f-916c-f6326233cbe1') {
    return this.http.get(environment.apiUrl + `report/${groupId}/${reportId}`);
  }

  getPBRList(): Observable<any> {
    return this.http.get(environment.apiUrl + `reports`);
  }

  getPBList(): Observable<any> {
    return this.http.get(environment.apiUrl + `dashboards`);
  }

}
