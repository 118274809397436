import {Component, OnChanges, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {PowerbiApiService} from "../../../shared/services/api/powerbi-api.service";
import {CompanyApiService} from "../../../shared/services/api/company-api.service";
import {CompanyModel} from "../../../shared/models/company.model";
import {environment} from "../../../../environments/environment.prod";
import {FileAndFoldersService} from "../../../shared/services/storage/file-and-folders.service";

@Component({
  selector: 'app-companies-single',
  templateUrl: './companies-single.component.html',
  styleUrls: ['./companies-single.component.scss']
})
export class CompaniesSingleComponent implements OnInit {

  companyId;
  company: CompanyModel;
  roles = [];
  selectedRole = '5fe0c7e3e68ed030dccdf9de';

  constructor(
    private route: ActivatedRoute,
    private companyApi: CompanyApiService,
    private ffService: FileAndFoldersService
  ) { }

  ngOnInit(): void {
    this.selectedRole = '5fe0c7e3e68ed030dccdf9de';
    this.ffService.setAdminRole(this.selectedRole);
    this.roles = JSON.parse(localStorage.getItem('roles')).filter(role => role.name !== 'Admin');
    this.companyId = this.route.snapshot.paramMap.get("companyId");
    this.companyApi.getCompany(this.companyId).subscribe((res: CompanyModel) => {
      this.company = res;
    }, err => {
      // console.log(err);
    })
  }

  setRole(event) {
    this.ffService.setAdminRole(this.selectedRole);
  }

}

