import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {CompanyApiService} from "../shared/services/api/company-api.service";
import {SnackService} from "../shared/services/snack/snack.service";

@Component({
  selector: 'app-public',
  templateUrl: './public.component.html',
  styleUrls: ['./public.component.scss']
})
export class PublicComponent implements OnInit {

  constructor(
    private snack: SnackService
  ) {}

  ngOnInit(): void {
    // this.snack.openSnackBar('You have successfully logged in.');
  }


}
