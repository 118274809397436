<div class="ui visible left vertical inverted sidebar menu">
  <div class="logo" style="background-image: url(/assets/img/volanteGlobalGreen.svg)" routerLink="/portal/"></div>
  <a class="item" routerLink="/portal/report/" routerLinkActive="active" *ngIf="powerBI?.dashboard">
    <span class="material-icons">dashboard</span>
    <span class="menu-item">Report</span>
  </a>
  <a class="item" routerLink="/portal/documents/" routerLinkActive="active">
    <span class="material-icons">account_tree</span>
    <span class="menu-item">Documents</span>
  </a>
  <a class="item" routerLink="/portal/settings/" routerLinkActive="active">
    <span class="material-icons">settings</span>
    <span class="menu-item">Settings</span>
  </a>
</div>
<div class="pusher">
  <mat-toolbar color="primary">
    <mat-toolbar-row>
      <span class="spacer"></span>
      <div class="userSettingsMenu" [matMenuTriggerFor]="menu">
        <span>{{user}}</span>
        <mat-icon class="menuSettingsMenu" aria-hidden="false" aria-label="User Settings Menu">account_circle</mat-icon>
      </div>
      <mat-menu #menu="matMenu">
        <button mat-menu-item routerLink="/portal/settings/">
          <mat-icon>settings</mat-icon>
          User Settings
        </button>
        <button mat-menu-item (click)="logout()">
          <mat-icon>exit_to_app</mat-icon>
          Logout
        </button>
      </mat-menu>
    </mat-toolbar-row>
  </mat-toolbar>

  <router-outlet></router-outlet>
</div>
