<div class="ui stackable grid" *ngIf="display">
  <div class="ten wide column">
    <div class="title-box">
      <h2>Report</h2>
      <span>View your companies overall performance</span>
    </div>
  </div>
  <div class="six wide right aligned column">
    <button mat-raised-button color="primary" (click)="fullscreen()">Fullscreen</button>
  </div>
  <div class="sixteen wide column">
    <div id="dashboardPowerBi"></div>
  </div>
</div>
