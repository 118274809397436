<div class="ui visible left vertical inverted sidebar menu">
  <div class="logo" style="background-image: url(/assets/img/volanteGlobalGreen.svg)" ></div>
  <a class="item">
    <span class="material-icons">dashboard</span>
    <span class="menu-item">Dashboard</span>
  </a>
  <a class="item">
    <span class="material-icons">account_tree</span>
    <span class="menu-item">Documents</span>
  </a>
  <a class="item">
    <span class="material-icons">settings</span>
    <span class="menu-item">Settings</span>
  </a>
</div>
<div class="pusher">
  <mat-toolbar color="primary">
    <mat-toolbar-row>
      <span class="spacer"></span>
      <div class="userSettingsMenu" [matMenuTriggerFor]="menu">
<!--        <span>{{user}}</span>-->
        <mat-icon class="menuSettingsMenu" aria-hidden="false" aria-label="User Settings Menu">account_circle</mat-icon>
      </div>
      <mat-menu #menu="matMenu">
        <button mat-menu-item >
          <mat-icon>settings</mat-icon>
          User Settings
        </button>
        <button mat-menu-item>
          <mat-icon>exit_to_app</mat-icon>
          Logout
        </button>
      </mat-menu>
    </mat-toolbar-row>
  </mat-toolbar>

  <div class="ui stackable grid" *ngIf="display">
    <div class="ten wide column">
      <div class="title-box">
        <h2>Reports</h2>
        <span>View your companies overall performance</span>
      </div>
    </div>
    <div class="six wide right aligned column">
      <button mat-raised-button color="primary" (click)="fullscreen()">Fullscreen</button>
    </div>
    <div class="sixteen wide column">
      <div id="dashboardPowerBi"></div>
    </div>
  </div>
</div>

