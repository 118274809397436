import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {CompanyApiService} from "../../../shared/services/api/company-api.service";
import {CompanyModel} from "../../../shared/models/company.model";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {SharepointApiService} from "../../../shared/services/api/sharepoint-api.service";
import {Router} from "@angular/router";
import {MatTableDataSource} from "@angular/material/table";
import {PowerbiApiService} from "../../../shared/services/api/powerbi-api.service";
import {FileAndFoldersService} from "../../../shared/services/storage/file-and-folders.service";
import {MatPaginator} from "@angular/material/paginator";
import {SnackService} from "../../../shared/services/snack/snack.service";
import {MatSort} from "@angular/material/sort";

@Component({
  selector: 'app-companies-list',
  templateUrl: './companies-list.component.html',
  styleUrls: ['./companies-list.component.scss']
})
export class CompaniesListComponent implements OnInit {

  ELEMENT_DATA: CompanyModel[];
  displayedColumns: string[] = ['name', 'actions'];
  dataSource = new MatTableDataSource([]);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  confirmDelete = false;
  confirmDeleteID = null;

  constructor(
    public dialog: MatDialog,
    private companyApi: CompanyApiService,
    private router: Router
  ) { }


  ngOnInit(): void {
    this.getCompanies();
  }

  getCompanies() {
    this.companyApi.getAllCompanies().subscribe((res: CompanyModel[]) => {
      res = res.sort(this.compare)
      this.dataSource = new MatTableDataSource(res);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    })
  }

  compare( a, b ) {
    if ( a.name < b.name ){
      return -1;
    }
    if ( a.name > b.name ){
      return 1;
    }
    return 0;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  openDialog(edit = false, data: CompanyModel = {name: null, powerBiKey: null, sharepointKey: []}) {
    const dialogRef = this.dialog.open(CompanyDialog, {width: '600px', data: {edit, data}});

    dialogRef.afterClosed().subscribe((result) => {
      setTimeout(() => {
        this.getCompanies();
      }, 500);
    });
  }

  goto(id) {
    this.router.navigate(['/admin/companies/' + id]);
  }

  deleteCompany(id) {
    const dialogRef = this.dialog.open(CompanyDeleteDialog, {width: '250px', data: {id}});

    dialogRef.afterClosed().subscribe((result) => {
      setTimeout(() => {
        this.getCompanies();
      }, 500);
    });
  }

}

@Component({
  selector: 'company-dialog',
  templateUrl: './company.dialog.html',
})
export class CompanyDialog implements OnInit{

  companyForm;
  editing = false;
  sharepointKey;
  powerBIDashboards;
  dataBound;
  roles;
  selected = [];
  folders;
  refreshing = false;
  parents = [];

  constructor(
    public dialogRef: MatDialogRef<CompanyDialog>,
    private sharePointApi: SharepointApiService,
    private companyApi: CompanyApiService,
    private powerBiAPI: PowerbiApiService,
    @Inject(MAT_DIALOG_DATA) public data,
    private folderStore: FileAndFoldersService,
    private foldersS: FileAndFoldersService,
    private snack: SnackService
  ) {
    this.roles = JSON.parse(localStorage.getItem('roles')).filter(role => role.name !== 'Admin');
    this.editing = (data.edit);
    this.dataBound = data;
    this.selected = data.data.sharepointKey;
    this.companyForm = new FormGroup({
      name: new FormControl(data.data.name, Validators.required),
      powerBiKey: new FormControl(data.data.powerBiKey),
      sharepointKey: new FormControl(data.data.sharepointKey),
    });
    // console.log(this.companyForm.value);
    // this.refreshFolders();
    this.powerBiAPI.getPBRList().subscribe((res) => {
      this.powerBIDashboards = res.reports;
    })
  }

  ngOnInit() {
    this.sharepointKey = this.folderStore.listAllFolders();
  }

  toJSON(value) {
    return JSON.parse(value);
  }

  stringify(value) {
    return JSON.stringify(value);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  refreshFolders() {
    this.refreshing = true;
    this.sharepointKey = this.folderStore.getAllFolders();
    this.refreshing = false;
  }

  saveCompany() {
    this.companyForm.value.sharepointKey = JSON.stringify(this.folderStore.getFolders());
    if (this.editing) {
      this.companyApi.updateCompany(this.dataBound.data._id, this.companyForm.value).subscribe((res) => {
        this.dialogRef.close();
        this.snack.openSnackBar('Company Updated.');
      }, err => {
        this.snack.openSnackBar('Error updating company.', 'error');
        // console.log(err);
      });
    } else {
      this.companyApi.addCompany(this.companyForm.value).subscribe((res) => {
        this.dialogRef.close();
        this.snack.openSnackBar('Company Saved.');
      }, err => {
        this.snack.openSnackBar('Error saving company.', 'error');
        // console.log(err);
      });
    }
  }

}

@Component({
  selector: 'company-delete-dialog',
  templateUrl: './company-delete.dialog.html',
})
export class CompanyDeleteDialog {
  dataBound;
  constructor(
    public dialogRef: MatDialogRef<CompanyDialog>,
    private companyApi: CompanyApiService,
    @Inject(MAT_DIALOG_DATA) public data,
    private snack: SnackService
  ) {
    this.dataBound = data;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  deleteCompany() {
    this.companyApi.deleteCompany(this.dataBound.id).subscribe(() => {
      this.dialogRef.close();
      this.snack.openSnackBar('Company Deleted.');
    })
  }

}
