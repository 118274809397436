<div class="files" *ngIf="loading || downloading" style="padding: 20px;">
  <div class="ui active loader" *ngIf="loading"></div>
  <div class="ui active dimmer" style="top: unset; left: unset" *ngIf="downloading">
    <div class="ui text loader">Downloading</div>
  </div>
</div>
<div class="files" *ngIf="!loading">
  <div class="header">
    <div class="name">Name</div>
    <div class="modified">Modified</div>
    <div class="size">Size</div>
  </div>
  <div class="upLevel entity" *ngIf="currentDir !== rootDir || !isInit" (click)="showInitFolders()">
    <div class="type" style="display: flex">
      <span class="material-icons">
        subdirectory_arrow_left
      </span><span style="padding-top: 3px;padding-left: 10px;">Home</span>
    </div>
  </div>
  <div class="folder entity" *ngFor="let folder of folders" (click)="goToFolder(folder)">
    <div class="type">
      <span class="material-icons">
        folder_open
      </span>
    </div>
    <div class="name">{{folder.name}}</div>
    <div class="modified">{{folder.modified | date : 'short'}}</div>
    <div class="size">-</div>
  </div>
  <div class="file entity" *ngFor="let file of files">
    <div class="type">
      <span class="material-icons">
        insert_drive_file
      </span>
    </div>
    <div class="name" (click)="downloadFile(file, currentDir, file.name)">{{file.name}}</div>
    <div class="modified">{{file.modified | date : 'short'}}</div>
    <div class="size">{{file?.size | filesize}}</div>
  </div>
  <div class="noContent" *ngIf="files?.length === 0 && folders?.length === 0">
    <div style="text-align: center; padding: 20px;">No folders or files in this directory.</div>
  </div>
</div>
