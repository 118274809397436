<div class="ui grid" style="margin-bottom: 0px;">
  <div class="ten wide column">
    <div class="title-box">
      <h2>Users</h2>
    </div>
  </div>
<!--  <div class="six wide right aligned column">-->
<!--    <button mat-raised-button color="primary" (click)="openDialog(false)">-->
<!--      Create User-->
<!--    </button>-->
<!--  </div>-->
</div>
<div class="example-loading-shade"
     *ngIf="loading">
  <div class="ui active loader"></div>
</div>
<table mat-table [dataSource]="dataSource" class="mat-elevation-z1">
  <ng-container matColumnDef="fname">
    <th mat-header-cell *matHeaderCellDef> First Name. </th>
    <td mat-cell *matCellDef="let element"> {{element.firstName}} </td>
  </ng-container>
  <ng-container matColumnDef="lname">
    <th mat-header-cell *matHeaderCellDef> Last Name. </th>
    <td mat-cell *matCellDef="let element"> {{element.lastName}} </td>
  </ng-container>
  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef> Email. </th>
    <td mat-cell *matCellDef="let element"> {{element.email}} </td>
  </ng-container>
  <ng-container matColumnDef="role">
    <th mat-header-cell *matHeaderCellDef> Role. </th>
    <td mat-cell *matCellDef="let element"> {{getRoleName(element?.permissionLevel)}} </td>
  </ng-container>
  <ng-container matColumnDef="active">
    <th mat-header-cell *matHeaderCellDef> Active. </th>
    <td mat-cell *matCellDef="let element"> <mat-slide-toggle (change)="activeUser($event, element.id)" [checked]="element?.active"></mat-slide-toggle> </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
