import { Component, OnInit } from '@angular/core';
import * as Msal from 'msal';
import {environment} from '../../environments/environment';
import {Router} from "@angular/router";


@Component({
  selector: 'app-private',
  templateUrl: './private.component.html',
  styleUrls: ['./private.component.scss']
})
export class PrivateComponent implements OnInit {

  msalConfig = {
    auth: {
      clientId: environment.clientId,
      tenantId: environment.tenantId
    }
  };
  msalInstance = new Msal.UserAgentApplication(this.msalConfig);
  user;
  company;
  powerBI;

  constructor(private router: Router) { }

  ngOnInit(): void {
    if (!sessionStorage.getItem('msal.idtoken')) {
      this.router.navigate(['/']);
    } else {
      this.user = this.msalInstance.getAccount()?.name;
      sessionStorage.getItem('msal.idtoken');
      localStorage.getItem('ms-vp');
    }
    let user = JSON.parse(localStorage.getItem('user'));
    if (!user) {
      this.router.navigate(['/']);
    }
    this.company = JSON.parse(localStorage.getItem('company'));
    if (this.company.powerBiKey !== "" && this.company.powerBiKey !== null) {
      this.powerBI = JSON.parse(this.company.powerBiKey);
    }
  }

  logout(): void {
    const logoutRequest = {
      account: this.msalInstance.getAccount().userName
    }
    this.thePurge();
    this.msalInstance.logout('msal.idtoken');
  }

  thePurge() {
    localStorage.clear();
    sessionStorage.clear();
    let cookies = document.cookie;

    if (cookies.length > 0) {
      for (let i = 0; i < cookies.split(";").length; ++i) {
        let myCookie = cookies[i];
        let pos = myCookie.indexOf("=");
        let name = pos > -1 ? myCookie.substr(0, pos) : myCookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
      }
    }
    this.router.navigate(['/']);
  }
}
