import { Component, OnInit } from '@angular/core';
import {environment} from "../../environments/environment";
import * as Msal from "msal";
import {Router} from "@angular/router";
import {UsersApiService} from "../shared/services/api/users-api.service";
import {RolesService} from "../shared/services/api/roles.service";
import {FileAndFoldersService} from "../shared/services/storage/file-and-folders.service";

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  msalConfig = {
    auth: {
      clientId: environment.clientId,
      tenantId: environment.tenantId
    }
  };
  msalInstance = new Msal.UserAgentApplication(this.msalConfig);
  user;

  constructor(
    private router: Router,
    private userApi: UsersApiService,
    private roleApi: RolesService,
    private foldersS: FileAndFoldersService
  ) { }

  ngOnInit(): void {
      let admin = JSON.parse(localStorage.getItem('user'));
      if (!admin || admin.permissionLevel !== '5fe0c272e68ed030dccdf9dd') {
        this.router.navigate(['/']);
      }
      this.userApi.userExists(this.msalInstance.getAccount()?.userName.toLowerCase()).subscribe(() => {
        this.roleApi.getAllRoles().subscribe((res) => {
          localStorage.setItem('roles', JSON.stringify(res));
        })
        this.foldersS.getAllFolders();
        this.user = this.msalInstance.getAccount()?.name;
        sessionStorage.getItem('msal.idtoken');
        localStorage.getItem('ms-vp');
      }, (err) => {
        // console.log('error', err);
        this.router.navigate(['/']);
      })
    const logoutRequest = {
      account: this.msalInstance.getAccount().userName.toString()
    }
      // console.log(logoutRequest);
  }

  logout(): void {
    const logoutRequest = {
      account: this.msalInstance.getAccount().userName
    }
    this.msalInstance.logout('msal.idtoken');
    this.thePurge();
  }

  thePurge() {
    localStorage.clear();
    sessionStorage.clear();
    let cookies = document.cookie;

    for (let i = 0; i < cookies.split(";").length; ++i)
    {
      let myCookie = cookies[i];
      let pos = myCookie.indexOf("=");
      let name = pos > -1 ? myCookie.substr(0, pos) : myCookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
    this.router.navigate(['/']);
  }
}
